import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { Layout } from "src/components/layout"
import { FictionItem } from "src/components/fiction"

const StoriesContainer = styled.div `
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 1024px) {
    width: 640px;
    margin: 2.5rem 0;
  }
`

const StoriesSection = styled.div `
  margin: 2rem 0;
`

const StoriesIntro = styled.div `
`

const Title = styled.h1 `
    margin-top: 0rem;
  margin-bottom: 0;
  color: #000;
  font-size: 2.5rem;
  line-height: 1.25;
  font-weight: 300;
`

const Blurb = styled.p `
  margin-top: 1.5rem;
  font-size: 1.25rem;
  color: #000;
  display: none;
`

export default ({ data }) => (
  <Layout>
    <Helmet>
      <title>Stories | {data.site.siteMetadata.title}</title>
    </Helmet>
    <StoriesContainer>
      <StoriesIntro>
        <Title>
          Stories
        </Title>
        <Blurb>
          I’ve made a selection of my short stories free to read. I’m curious to hear your thoughts and feedback!
        </Blurb>

      </StoriesIntro>
      <StoriesSection>
        {data.allMarkdownRemark.edges.map(({ node }) => (  
          <FictionItem 
            title={node.frontmatter.title}
            byLine="By Cristina Hartmann"
            coverImage={node.frontmatter.thumbnail && node.frontmatter.thumbnail.childImageSharp.fixed.src}
            path={node.fields.slug}
            date={node.frontmatter.date}
          />
        ))}
      </StoriesSection>
    </StoriesContainer>
    {/* <AsideContainer>
      <FeedbackSection>
        <FeedbackTitle>
          A small ask...
        </FeedbackTitle>
        <FeedbackP>
          If you read my stories, I’d really appreciate if you’d share your thoughts with me. Thanks!
        </FeedbackP>
        <FeedbackButton>
          Share feedback
        </FeedbackButton>
      </FeedbackSection>

    </AsideContainer> */}

  </Layout>
)

export const query = graphql`
  query {
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}
      filter: { frontmatter: { type: { eq: "fiction" } } } ) {
      totalCount
      edges {
        node {
          id
          frontmatter{
            title
            date(formatString: "YYYY")
            type
            thumbnail {
              childImageSharp {
                fixed(width: 276) {
                  width
                  height
                  src
                  srcSet
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`